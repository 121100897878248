import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './Card.module.scss';
import Button from '../Button';
import Image from '../Image';
import isEmpty from '../../utils/isEmpty';
import RentalTag from '../RentalTag';

const Card = ({
    infoLabel,
    title,
    href,
    text,
    image,
    type,
    rentalTag,
    rentalAreaName,
    sizeModifier,
    modifier,
    isEven,
    button,
    idx,
}) => {
    const Tag = !isEmpty(button) ? 'div' : 'a';
    let props = {};

    const cardClass = classNames(styles['Card'], {
        [styles[`Card--${sizeModifier}`]]: !!sizeModifier,
        [styles['Card--Rental']]: type === 'rental',
        [styles['Card--Area']]: type === 'area',
        [styles['Card--Link']]: isEmpty(button),
        [styles[`Card--${modifier}`]]: !!modifier,
        [styles[`Card--IsEven`]]: isEven,
    });

    if (href) {
        props.href = href;
    }

    let buttonSize = null;

    if (sizeModifier && parseInt(sizeModifier, 10) === 100) {
        buttonSize = 'Small';
    }

    if (modifier && modifier === 'Rental') {
        buttonSize = 'Small';
    }

    return (
        <Tag {...props} key={idx} className={cardClass}>
            {!isEmpty(image) && (
                <div className={styles['Card__Image']}>
                    <Image maxImageWidth={1020} {...image} altText={''} />
                    {type === 'rental' && rentalTag && (
                        <RentalTag
                            rentalTag={rentalTag}
                            rentalAreaName={rentalAreaName}
                        />
                    )}
                </div>
            )}
            <div className={styles['Card__Content']}>
                <div className={styles['Card__ContentInner']}>
                    {type === 'rental' && infoLabel && (
                        <span className={styles['Card__InfoLabel']}>
                            {infoLabel}
                        </span>
                    )}
                    <h2 className={styles['Card__Title']}>{title}</h2>
                    <p
                        className={styles['Card__Text']}
                        dangerouslySetInnerHTML={{ __html: text }}
                    ></p>
                </div>
                {!isEmpty(button) && (
                    <div className={styles['Card__Button']}>
                        <Button {...button} size={buttonSize} />
                    </div>
                )}
            </div>
        </Tag>
    );
};

Card.propTypes = {
    infoLabel: PropTypes.string,
    title: PropTypes.string,
    href: PropTypes.string,
    text: PropTypes.string,
    image: PropTypes.object,
    type: PropTypes.string,
    button: PropTypes.object,
    idx: PropTypes.string,
    isEven: PropTypes.bool,
    size: PropTypes.number,
    modifier: PropTypes.string,
};

Card.defaultProps = {
    title: '',
    href: '',
    text: '',
    image: {},
    type: '',
    button: {},
    idx: '',
    isEven: false,
    size: 0,
    modifier: '',
};

export default Card;
