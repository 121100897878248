import React from 'react';
import PropTypes from 'prop-types';
import styles from './Image.module.scss';

const DEFAULT_MAP_WIDTHS = {
    small: 768,
    medium: 1020,
    large: 1280,
    xlarge: 1800,
    xxlarge: 2400,
};

const swapKeyAndValue = (o, r = {}) =>
    Object.keys(o).map((k) => (r[o[k]] = k)) && r;

const Image = ({
    altText,
    file,
    renditions,
    focal,
    mapWidths = DEFAULT_MAP_WIDTHS,
    maxImageWidth,
}) => {
    const { x, y } = focal;
    let style = { objectPosition: `50% 50%` };

    if (x && y) {
        style = {
            objectPosition: `${x} ${y}`,
        };
    }

    const mediumSource = renditions.medium ? renditions.medium.src : file;
    let defaultImage = mediumSource;

    if (renditions.medium && maxImageWidth < DEFAULT_MAP_WIDTHS.medium) {
        const newDefaultRendition =
            swapKeyAndValue(DEFAULT_MAP_WIDTHS)[maxImageWidth];
        defaultImage = renditions[newDefaultRendition]?.src || mediumSource;
    }

    return (
        file && (
            <picture tabIndex={-1}>
                {Object.keys(renditions).map((rendition) => {
                    if (maxImageWidth && mapWidths[rendition] > maxImageWidth) {
                        return null;
                    }
                    return (
                        <source
                            key={rendition}
                            media={`(max-width: ${mapWidths[rendition]}px)`}
                            srcSet={renditions[rendition].src}
                        />
                    );
                })}
                <img
                    tabIndex={0}
                    loading="lazy"
                    src={defaultImage}
                    alt={altText}
                    style={style}
                />
            </picture>
        )
    );
};

Image.propTypes = {
    altText: PropTypes.string,
    file: PropTypes.string,
    renditions: PropTypes.object,
    focal: PropTypes.object,
    maxImageWidth: PropTypes.number,
};

Image.defaultProps = {
    altText: '',
    file: '',
    renditions: {},
    maxImageWidth: null,
    focal: { x: '50%', y: '50%' },
};

export default Image;
