import React from 'react';
import PropTypes from 'prop-types';
import styles from './CardListHalf.module.scss';
import Card from '../Card';
import isEmpty from '../../utils/isEmpty';

const CardListHalf = ({ items }) => {
    return (
        <div className={styles['CardListHalf']}>
            {!isEmpty(items) &&
                items.map(({ title, href, text, image, type, button }, idx) => {
                    return (
                        <Card
                            key={idx}
                            title={title}
                            href={href}
                            text={text}
                            image={image}
                            type={type}
                            isEven={idx % 2 != 0}
                            modifier={'CardListHalf'}
                            button={button}
                        />
                    );
                })}
        </div>
    );
};

CardListHalf.propTypes = {
    items: PropTypes.array,
};

CardListHalf.defaultProps = {
    items: [],
};

export default CardListHalf;
