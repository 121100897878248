import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import i18n from '../../i18n';

import styles from './RentalTag.module.scss';

const RentalTag = ({ rentalTag /* rentalAreaName */ }) => {
    const tagClass = classNames(styles['RentalTag'], {
        [styles[`RentalTag--${rentalTag}`]]: rentalTag,
    });

    /* const Label = */
    /*     rentalTag === 'done' ? rentalAreaName : t(`rentalTag.${rentalTag}`); */

    return (
        <div className={tagClass}>
            <span>{i18n.t(`rentalTag.${rentalTag}`)}</span>
        </div>
    );
};

RentalTag.propTypes = {
    rentalTag: PropTypes.string,
    rentalAreaName: PropTypes.string,
};

RentalTag.defaultProps = {
    rentalTag: '',
    rentalAreaName: '',
};

export default RentalTag;
